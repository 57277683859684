<template>
  <!-- Sidenav -->
  <nav class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white" id="sidenav-main">
    <div class="scrollbar-inner">
      <!-- Brand -->
      <div class="sidenav-header align-items-center">
        <a class="navbar-brand" href="javascript:void(0)">
          <img src="/assets/img/logo.png" class="navbar-brand-img" alt="...">
        </a>
      </div>
      <div class="navbar-inner">
        <!-- Collapse -->
        <div class="collapse navbar-collapse" id="sidenav-collapse-main">
          <!-- Nav items -->
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link active" :to="{ name: 'AdminDashboard' }">
                <i class="ni ni-tv-2 text-primary"></i>
                <span class="nav-link-text">Dashboard</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link active" :to="{ name: 'Analytics' }">
                <i class="ni ni-tv-2"></i>
                <span class="nav-link-text">Analytics</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'AdminPlayersList' }">
                <i class="ni ni-user-run text-orange"></i>
                <span class="nav-link-text">Players</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'ThirdPartyList' }">
                <i class="ni ni-user-run text-orange"></i>
                <span class="nav-link-text">Third Party Data</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'AdminEventsList' }">
                <i class="ni ni-calendar-grid-58 text-primary"></i>
                <span class="nav-link-text">Events</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'EmailTemplates' }">
                <i class="ni ni-chat-round text-orange"></i>
                <span class="nav-link-text">Email Templates</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'AdminAppSettings' }">
                <i class="ni ni-settings-gear-65 text-dark"></i>
                <span class="nav-link-text">Settings</span>
              </router-link>
            </li>
          </ul>
          <!-- Divider -->
          <hr class="my-3">
          <!-- Heading -->
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'SideNavComponent',
  data() {
    return { }
  },
}
</script>

<style scoped type="text/css">
.navbar-brand {
  background-color: #000;
  display: block;
}
.navbar-vertical .navbar-brand-img, .navbar-vertical .navbar-brand > img {
  max-height: 4rem !important;
}
</style>
