import Vue from 'vue'
import Vuex from 'vuex'

import dashboard from './modules/dashboard'
import common from './modules/common'
import auth from './modules/auth'
import event from './modules/event'
import wordpress_form_data from './modules/event'
import user from './modules/user'
import player from './modules/player'
import analytics from './analytics'
import routes from './modules/routes'

Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        errors: [],
    },

    getters: {
        errors: state => state.errors,
    },

    mutations: {
        SET_ERRORS: (state, errors) => state.errors = errors,
    },

    actions: {},

    modules: {
        common, auth, dashboard, event,wordpress_form_data, user, player, analytics, routes,
    },
    strict: process.env.NODE_ENV !== 'production',
})
