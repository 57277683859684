import {mapGetters} from "vuex";

export default {
    name: 'login-form',
    components: {},
    props: [],
    data() {
        return {
            form: {
                email: null,
                password: null,
            }
        }
    },

    computed: {
        ...mapGetters('auth', ['loggedIn']),
        ...mapGetters(['errors']),
    },

    mounted() {
        // add contact class to body
        document.body.classList.add('contact');
    },
    destroyed() {
        document.body.classList.remove('contact')
    },

    methods: {
        loginHandler() {
            this.$store.dispatch('auth/login', this.form).then(res => {
                if (this.loggedIn) {
                    console.log(res);
                    this.$router.push({name: 'AdminDashboard'});
                }
            });
        },
    },

}


