import Service from "@/services";

export default {
  namespaced: true,

  state: {
    users: [],
    settings: [],
  },

  getters: {
    users: (state) => state.users,
    settings: (state) => state.settings,
  },

  actions: {

    async users({commit}) {
      try {
        return await Service().get('users').then(res => {
          commit('SET_USERS', res.data);
          return res;
        });
      } catch (e) {
        console.log('Error: ', e);
      }
    },

    async updatePassword(_, payload) {
      try {
        return await Service().put('users/update_password', payload);
      } catch (e) {
        console.log('Error: ', e);
      }
    },

    async settings({commit}) {
      try {
        return await Service().get('settings').then(res => {
          commit('SET_SETTINGS', res.data);
          return res;
        });
      } catch (e) {
        console.log('Error: ', e);
      }
    },

    async update_settings({commit}, payload) {
      try {
        return await Service().post('settings/update', payload).then(res => {
          commit('SET_SETTINGS', res.data);
          return res;
        });
      } catch (e) {
        console.log('Error: ', e);
      }
    },

  },

  mutations: {
    SET_USERS: (state, payload) => state.users = payload,
    SET_SETTINGS: (state, payload) => state.settings = payload,
  }
}
