export const riderListRes = (res) => {
    const total = res?._shards?.total || 0;
    const list = [];

    for (const item of (res?.aggregations?.rider_list.buckets || [])) {
        list.push(item?.rider?.hits?.hits[0]?._source || null);
    }
    
    return {
        total,
        list
    }
}