<template>
  <!-- Header -->
  <div class="header bg-gradient-orange pb-6">
    <div class="container-fluid">
      <div class="header-body">
<!--        <div class="row align-items-center py-4">-->
<!--          <div class="col-lg-6 text-left">-->
<!--            <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">-->
<!--              <ol class="breadcrumb breadcrumb-links breadcrumb-dark">-->
<!--                <li class="breadcrumb-item"><router-link to="/admin"><i class="fas fa-home"></i></router-link></li>-->
<!--                <li class="breadcrumb-item"><router-link to="/admin">Pages</router-link></li>-->
<!--                <li class="breadcrumb-item active" aria-current="page">List of Pages</li>-->
<!--              </ol>-->
<!--            </nav>-->
<!--          </div>-->
<!--          <div class="col-lg-6 col-5 text-right">-->
<!--            <a href="#" class="btn btn-sm btn-neutral">New</a>-->
<!--            <a href="#" class="btn btn-sm btn-neutral">Filters</a>-->
<!--          </div>-->
<!--        </div>-->

<!--        &lt;!&ndash; Card stats &ndash;&gt;-->
<!--        <div class="row">-->
<!--          <div class="col-xl-3 col-md-6">-->
<!--            <div class="card card-stats">-->
<!--              &lt;!&ndash; Card body &ndash;&gt;-->
<!--              <div class="card-body">-->
<!--                <div class="row">-->
<!--                  <div class="col">-->
<!--                    <h5 class="card-title text-uppercase text-muted mb-0">Total traffic</h5>-->
<!--                    <span class="h2 font-weight-bold mb-0">350,897</span>-->
<!--                  </div>-->
<!--                  <div class="col-auto">-->
<!--                    <div class="icon icon-shape bg-gradient-red text-white rounded-circle shadow">-->
<!--                      <i class="ni ni-active-40"></i>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <p class="mt-3 mb-0 text-sm">-->
<!--                  <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>-->
<!--                  <span class="text-nowrap">Since last month</span>-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-xl-3 col-md-6">-->
<!--            <div class="card card-stats">-->
<!--              &lt;!&ndash; Card body &ndash;&gt;-->
<!--              <div class="card-body">-->
<!--                <div class="row">-->
<!--                  <div class="col">-->
<!--                    <h5 class="card-title text-uppercase text-muted mb-0">New users</h5>-->
<!--                    <span class="h2 font-weight-bold mb-0">2,356</span>-->
<!--                  </div>-->
<!--                  <div class="col-auto">-->
<!--                    <div class="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">-->
<!--                      <i class="ni ni-chart-pie-35"></i>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <p class="mt-3 mb-0 text-sm">-->
<!--                  <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>-->
<!--                  <span class="text-nowrap">Since last month</span>-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-xl-3 col-md-6">-->
<!--            <div class="card card-stats">-->
<!--              &lt;!&ndash; Card body &ndash;&gt;-->
<!--              <div class="card-body">-->
<!--                <div class="row">-->
<!--                  <div class="col">-->
<!--                    <h5 class="card-title text-uppercase text-muted mb-0">Sales</h5>-->
<!--                    <span class="h2 font-weight-bold mb-0">924</span>-->
<!--                  </div>-->
<!--                  <div class="col-auto">-->
<!--                    <div class="icon icon-shape bg-gradient-green text-white rounded-circle shadow">-->
<!--                      <i class="ni ni-money-coins"></i>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <p class="mt-3 mb-0 text-sm">-->
<!--                  <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>-->
<!--                  <span class="text-nowrap">Since last month</span>-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-xl-3 col-md-6">-->
<!--            <div class="card card-stats">-->
<!--              &lt;!&ndash; Card body &ndash;&gt;-->
<!--              <div class="card-body">-->
<!--                <div class="row">-->
<!--                  <div class="col">-->
<!--                    <h5 class="card-title text-uppercase text-muted mb-0">Performance</h5>-->
<!--                    <span class="h2 font-weight-bold mb-0">49,65%</span>-->
<!--                  </div>-->
<!--                  <div class="col-auto">-->
<!--                    <div class="icon icon-shape bg-gradient-info text-white rounded-circle shadow">-->
<!--                      <i class="ni ni-chart-bar-32"></i>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <p class="mt-3 mb-0 text-sm">-->
<!--                  <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>-->
<!--                  <span class="text-nowrap">Since last month</span>-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HeaderComponent',
    data() {
      return {

      }
    },
  }
</script>
