export const riderListQuery = (payload, countOnly = false) => {
    return {
        q: payload.searchText, 
        body: {
            size: 0,
            query: {
                "bool": {
                    "must": [
                        {
                            range: {
                                "@timestamp": {
                                    "gte": payload.dateRange.startDate,
                                    "lt": payload.dateRange.endDate,
                                }
                            }
                        },
                        
                    ],
                }
            },
            aggs: {
                "rider_list": {
                    terms: {
                        "field" : "UserId.keyword",
                        "size": payload.size,
                    },
                    ...(countOnly ? {} : { 
                        aggs: {
                            "rider": {
                                "top_hits": {
                                    "size": 1,
                                    "_source": {
                                        "includes": [
                                            "RouteName",
                                            "Username",
                                            "Power", 
                                            "PlayerWeight", 
                                            "Speed", 
                                            "MaxRPM",
                                            "HeartRate",
                                            "Distance" 
                                        ]
                                    },
                                    "sort": {
                                            "logtime": "desc"
                                    }
                                    
                                }
                            }
                        }
                    })
                }
            }
        }
    }
};