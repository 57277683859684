import Vue from 'vue';
import App from '@/App.vue';
import VueRouter from 'vue-router';
import store from '@/store'; // vuex store

import Vuelidate from 'vuelidate'
import VueToastr2 from 'vue-toastr-2';
import 'vue-toastr-2/dist/vue-toastr-2.min.css';

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

window.toastr = require('toastr');

// configs
Vue.config.productionTip = false;

// use statements
Vue.use(VueRouter);
Vue.use(VueToastr2);
Vue.use(Vuelidate);

// routes
import routes from '@/routes';

const router = new VueRouter({
    mode: 'history',
    routes,
});

const app = new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')
global.app = app;