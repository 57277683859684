const participantResponse = (data) => {
  Object.values(data).forEach((p) => {
    const modify = [
      "created_at",
      "updated_at",
      "removed_at",
      "expiryDate",
      "approved_at",
      "declined_at",
      "userId",
      "decline_reason",
      "status",
      "otpVerified",
      "otpVerifyBy",
      "eventId",
      "_id",
      "agree",
    ];

    const phone = ["code", "phone"];

    const common = {};
    modify.forEach((e) => {
      common[e] = p[e];
      delete p[e];
    });
    p.common = common;

    p.phoneWithCountry = "+";
    phone.forEach((e) => {
      if (p.phoneWithCountry !== "+" && e === "phone") {
        p.phoneWithCountry += p[e];
      } else {
        p.phoneWithCountry += p[e];
      }
    });

    return p;
  });

  return data;
};

export { participantResponse };
