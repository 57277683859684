export default [
    {
        path: '',
        name: 'AdminDashboard',
        component: () => import('@/views/Dashboard'),
    },
    {
        path: 'players',
        name: 'AdminPlayersList',
        component: () => import('@/views/players/List'),
    },
    {
        path: 'players/view/:id',
        name: 'AdminPlayersEdit',
        component: () => import('@/views/players/Edit'),
    },
    {
        path: 'third_party_data',
        name: 'ThirdPartyList',
        component: () => import('@/views/third_party/List'),
    },
    {
        path: 'third_party_data/view/:id',
        name: 'ThirdPartyDetail',
        component: () => import('@/views/third_party/Edit'),
    },
    {
        path: 'events/wordpress-data/:id',
        name: 'EventWordpressData',
        component: () => import('@/views/events/WordpressDataList'),
    },
    {
        path: 'events',
        name: 'AdminEventsList',
        component: () => import('@/views/events/List'),
    },
    {
        path: 'events/edit/:id',
        name: 'AdminEventsEdit',
        component: () => import('@/views/events/Edit'),
    },
    {
        path: 'events/participants/:id',
        name: 'AdminEventsParticipants',
        component: () => import('@/views/events/Participants'),
    },
    {
        path: 'events/images/:id',
        name: 'AdminEventsImages',
        component: () => import('@/views/events/Images'),
    },
    {
        path: 'events/participants/:id/participant/:participantId',
        name: 'participant-details',
        component: () => import('@/views/events/Participant'),
    },
    {
        path: 'settings',
        name: 'AdminAppSettings',
        component: () => import('@/views/settings/Edit'),
    },
    {
        path: 'emails',
        name: 'EmailTemplates',
        component: () => import('@/views/emails/List'),
    },
];
