import Service from "@/services";

export default {
    namespaced: true,

    state: {
        routes: []
    },
    getters: {
        getRoutes: (state) => state.routes,
    },
    mutations: {
        SET_ROUTES: (state, payload) => state.routes = payload,
    },
    actions: {
        async loadRoutes({commit}) {
            try {
                await Service().get('map-route').then(res => {
                    commit('SET_ROUTES', [{ label: "Select Route", value: null }, ...res.data]);
                });
            }
            catch (e) {
                console.log(e)
            }
        },
    }
};