import Service from "@/services";

export default {
    namespaced: true,

    state: {
        players: {},
        third_party_data: {},
        third_party_detail: {},
        playerProfile: {},
        errors: null
    },

    getters: {
        getErrors: (state) => state.errors,
        players: (state) => state.players,
        third_party_data: (state) => state.third_party_data,
        playerProfile: (state) => state.playerProfile,
        third_party_detail: (state) => state.third_party_detail
    },

    actions: {
        async players({commit}, paylod) {
            try {

                const page = paylod.page;
                const email = paylod.email ? `&email=${paylod.email}` : '';
                const id = paylod.id ? `&id=${paylod.id}` : '';
                return await Service().get(`players?page=${page}${email}${id}`).then(res => {
                    commit('SET_PLAYERS', res.data);
                    return res;
                });
            } catch (e) {
                console.log('Error: ', e);
            }
        },

        async getProfile({commit}, payload) {
            try {
                const res = await Service().get(`players/get/${payload.id}`);
                if (res.status) {
                    commit('SET_PLAYER_PROFILE', res.data);
                }
            } catch (e) {
                console.log('Error: ', e);
            }
        },

        async updateProfile({commit}, payload) {
            try {
                await Service().put(`players/update`, payload);
            } catch (e) {
                commit("SET_ERRORS", Object.values(e.errors).join('<br />'), {root: true});
                console.log('Error: ', e);
            }
        },

        async downloadFile({commit}, payload) {
            try {
                return await Service().post(`players/file/download`, payload);
            } catch (e) {
                commit("SET_ERRORS", Object.values(e.errors).join('<br />'), {root: true});
                console.log('Error: ', e);
            }
        },

        async postApprovedRequest(_, payload) {
            try {
                return await Service().post(`rider-profile/post-approve-request`, payload);
            } catch (e) {
                //commit("SET_ERRORS", Object.values(e.errors).join('<br />'), {root: true});
                console.log('Error: ', e);
            }
        },

        async postRejectRequest(_, payload) {
            try {
                return await Service().post(`rider-profile/post-reject-request`, payload);
            } catch (e) {
                //commit("SET_ERRORS", Object.values(e.errors).join('<br />'), {root: true});
                console.log('Error: ', e);
            }
        },

        async get_third_party_records({commit}, payload) {
            try {
                const page = payload.page;
                const search_form = payload.form ? `&${payload.form}` : '';
                return await Service().get(`rider-profile/get-all-third-party-records?page=${page}${search_form}`).then(res => {
                    commit('SET_THIRD_PARTY_DATA', res.data.third_party_data);
                    return res;
                });
            } catch (e) {
                console.log('Error: ', e);
            }
        },

        async get_third_party_detail({commit}, payload) {
            try {
                const res = await Service().get(`rider-profile/get-third-party-detail/${payload.id}`);

                if (res.status) {
                    commit('SET_THIRD_PARTY_DETAIL', res.data);
                    //return res
                }
            } catch (e) {
                console.log('Error: ', e);
            }
        },

        async updateThirdPartyRecord({commit}, payload) {
            try {
                await Service().post(`rider-profile/post-third-party-data`, payload);
            } catch (e) {
                commit("SET_ERRORS", Object.values(e.errors).join('<br />'), {root: true});
                console.log('Error: ', e);
            }
        },
    },

    mutations: {
        SET_ERRORS: (state, payload) => state.errors = payload,
        SET_PLAYERS: (state, payload) => state.players = payload,
        SET_THIRD_PARTY_DATA: (state, payload) => state.third_party_data = payload,
        SET_PLAYER_PROFILE: (state, payload) => state.playerProfile = payload,
        SET_THIRD_PARTY_DETAIL: (state, payload) => state.third_party_detail = payload
    }
}
