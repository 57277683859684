import { elApi } from "@/services/el.search";
import { riderListQuery } from "@/queries/analytics.qry";
import { riderListRes } from "@/utils/analytics.res";
import Service from "@/services";

export default {
    namespaced: true,

    state: {
        riderStats: {
          total: 0,
          list: []
        },
        riderCount:{
          day: 0,
          week: 0,
          month: 0
        },
        registerRiderCount: 0,
        allRidersCount: {
          playfabImportedUsers: 0, 
          gameVerifiedUsers: 0, 
          playfabVerifiedUsers: 0, 
          total: 0
        }
    },

    getters: {
      riderStats: ({riderStats}) => riderStats,
      riderCount: ({riderCount}) => riderCount,
      registerRiderCount: ({registerRiderCount}) => registerRiderCount,
      allRidersCount: ({allRidersCount}) => allRidersCount
    },

    actions: {
      async getRiderStats({commit}, payload) {
        const res = await elApi(riderListQuery(payload));

        if (res?.aggregations?.rider_list?.buckets?.length > 0) {
          commit('SET_RIDER_STATS', riderListRes(res))
        } else {
          commit('SET_RIDER_STATS', {
            total: 0,
            list: []
          })
        }
      },

      async getRiderCount({commit, state}, payload) {
        const res = await elApi(riderListQuery(payload, true));
        const riderCount = Object.assign({}, state.riderCount);

        if (res?._shards?.total > 0) {
          riderCount[payload.type] = res?._shards?.total;

          commit('SET_RIDER_COUNT', riderCount)
        }
      },

      async getRegisterRiderCount({commit}, payload) {
        return await Service().get(`players/count/${payload.startDate}/${payload.endDate}`).then(res => {
          commit('SET_REGISTER_RIDER_COUNT', res.data);
          return res;
        });
      },

      async getAllRiderCount({commit}) {
        return await Service().get(`players/countAll`).then(res => {
          res.data.playfabImportedUsers && commit('SET_ALL_RIDER_COUNT', res.data);
          
          return res;
        });
      }
    },

    mutations: {
      SET_RIDER_STATS: (state, payload) => state.riderStats = payload,
      SET_RIDER_COUNT: (state, payload) => state.riderCount = payload,
      SET_REGISTER_RIDER_COUNT: (state, payload) => state.registerRiderCount = payload,
      SET_ALL_RIDER_COUNT: (state, payload) => state.allRidersCount = payload
    }
}
