import Service from "@/services";

export default {
  namespaced: true,

  state: {
    user: {},
    loggedIn: null,
  },

  getters: {
    user: (state) => state.user,
    loggedIn: (state) => state.loggedIn,
  },

  actions: {
    async login({ commit }, payload) {
      try {
        return await Service()
          .post("auth/login", payload)
          .then((res) => {
            if (res.status) {
              const user = JSON.stringify(res.data.user);
              localStorage.setItem("adminToken", res.data.token);
              localStorage.setItem("adminUser", user);
              commit("SET_USER", JSON.parse(user));
              commit("SET_LOGGED_IN", true);
              return true;
            }
            localStorage.removeItem("adminToken");
            localStorage.removeItem("adminUser");
            commit("SET_USER", {});
            commit("SET_LOGGED_IN", false);
            return false;
          });
      } catch (e) {
        commit("SET_USER", {});
        commit("SET_LOGGED_IN", false);
        // console.log('Error: ', e);
        return false;
      }
    },

    logout({ commit }) {
      commit("SET_USER", {});
      commit("SET_LOGGED_IN", false);
      localStorage.removeItem("adminToken");
      localStorage.removeItem("adminUser");
    },

    loggedIn({ commit }) {
      const token = localStorage.getItem("adminToken");
      const user = localStorage.getItem("adminUser");
      const tokenPassed =
        token !== undefined &&
        token !== "undefined" &&
        token !== null &&
        token !== "null" &&
        token !== "";
      if (tokenPassed) {
        commit("SET_LOGGED_IN", true);
        commit("SET_USER", JSON.parse(user));
        return;
      }
      commit("SET_USER", {});
      commit("SET_LOGGED_IN", false);
    },

    async updatePassword(_, payload) {
      try {
        return await Service().put("change_password", payload);
      } catch (e) {
        console.log("Error: ", e);
      }
    },
  },

  mutations: {
    SET_USER: (state, payload) => (state.user = payload),
    SET_LOGGED_IN: (state, payload) => (state.loggedIn = payload),
  },
};
