import AdminLayout from '@/layouts/AdminLayout'
import LoginLayout from "@/layouts/LoginLayout";
import AnalyticLayout from '@/layouts/AnalyticLayout'
import AuthRoutes from '@/routes/auth';
import AnalyticsRoutes from '@/routes/analytics';

const checkToken = (to, from, next) => {
    const token = localStorage.getItem('adminToken');
    const tokenPassed = token !== undefined &&
        token !== "undefined" &&
        token !== null &&
        token !== "null" &&
        token !== "";
    if (tokenPassed) {
        return next();
    }
    return next({name: 'AdminLogin'});
}
const redirectToDashboard = (to, from, next) => {
    const token = localStorage.getItem('adminToken');
    const tokenPassed = token !== undefined &&
        token !== "undefined" &&
        token !== null &&
        token !== "null" &&
        token !== "";
    if (tokenPassed) {
        return next({name: 'AdminDashboard'});
    }
    return next();
}

export default [
    {
        path: '/login',
        name: 'AdminLogin',
        beforeEnter: redirectToDashboard,
        component: LoginLayout,
    },
    {
        path: '/analytics',
        component: AnalyticLayout,
        // beforeEnter: checkToken,
        meta: { requiresAuth: false },
        children: AnalyticsRoutes,
    },
    {
        path: '/',
        component: AdminLayout,
        beforeEnter: checkToken,
        meta: { requiresAuth: false },
        children: AuthRoutes,
    },
    {path: '*', redirect: '/'}
];
