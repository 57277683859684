<template>
  <div id="app">
    <Loading v-if="getLoading" />
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "@/components/shared/Loading";
import { computed } from 'vue';

export default {
  name: 'App',
  components: { Loading },
  computed: {
    ...mapGetters('common', ['getLoading', 'getDropdowns']),
  },
  mounted() {
    const token = localStorage.getItem("adminToken");
    if (token) {
      this.$store.dispatch("common/fetchDropdowns");
    }
  },
  provide() {
    return {
      dropdowns: computed(() => this.getDropdowns),
    };
  }
}
</script>

<style>
.tox-notifications-container {
  display: none;
}
</style>
