import axios from 'axios';
import store from '../store';

const Service = () => {

    const token = localStorage.getItem('adminToken');
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`,
    };

    const instance = axios.create({
        withCredentials: true,
        baseURL: process.env.VUE_APP_API_URL,
        headers
    });

    instance.interceptors.request.use(function (config) {
        // Do something before request is sent
        store.commit('common/SET_LOADING', true);
        store.commit("SET_ERRORS", {}, {root: true});
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    instance.interceptors.response.use(response => {
        store.commit('common/SET_LOADING', false)
        const data = response.data;
        if (data?.display) {
            global.app.$toastr.success(data.message);
            // sweetAlert.alert(data.message, 'success');
        }
        return data;
    }, error => {
        store.commit('common/SET_LOADING', false)

        if(!error.response) {
            // global.app.$toastr.error('An Unknown error has occurred. Please contact support');
            global.app.$toastr.error('No response received. We are regretted for inconvenience. please try later');
            return Promise.reject(error);
        }

        if (401 === error.response.status) {
            location.href = "/login";
            return ;
        }

        const data = error.response.data;
        if (data?.display) {
            global.app.$toastr.error(data.message);
        }

        if (422 === error.response.status) {
            global.app.$store.commit("SET_ERRORS", data.errors);
        }
        return Promise.reject(data);
    });

    return instance;
}

export default Service;
