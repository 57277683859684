var elasticsearch = require('elasticsearch');

var client = new elasticsearch.Client({
  host: process.env.VUE_APP_ELASTIC_HOST,
  //log: 'trace',
  apiVersion: '7.x', // use the same version of your Elasticsearch instance
  httpAuth: process.env.VUE_APP_ELASTIC_USER
});

client.ping({
    // ping usually has a 3000ms timeout
    requestTimeout: 1000
  }, function (error) {
    if (error) {
      console.trace('elasticsearch cluster is down!');
    } else {
      console.log('All is well');
    }
});

export const elApi = async (query = {}) => { 
    try {
        return await client.search({
            index: "mywhoosh-ridelog-*",
            ...query
        });
    } catch (error) {
        console.trace(error.message)
        return null;
    }
}
