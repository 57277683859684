import Service from "@/services";

export default {
  namespaced: true,
  state: {
    dashboard_data: [],
  },
  getters: {
    getEventData: (state) => {
      return state.dashboard_data.eventDetails;
    },
    getParticipantData: (state) => {
      return state.dashboard_data.participantDetails;
    },
    getFaqData: (state) => {
      return state.dashboard_data.faqDetails;
    },
    getPageData: (state) => {
      return state.dashboard_data.pageDetails;
    },
  },
  mutations: {
    SET_DASHBOARD_DATA: (state, payload) => (state.dashboard_data = payload),
  },
  actions: {
    async getAll({ commit }) {
      await Service()
        .get("dashboard")
        .then((res) => {
          commit("SET_DASHBOARD_DATA", res.data);
        });
    },
  },
};
