import Service from "@/services";

export default {
    namespaced: true,

    state: {
        loading: false,
        emails: {},
        countries: [],
        dropdowns: []
    },

    getters: {
        getLoading: (state) => state.loading,
        getEmails: (state) => state.emails,
        getCountries: (state) => state.countries,
        getDropdowns: (state) => state.dropdowns,
    },

    actions: {
        async getEmailContents({commit}) {
            try {
                return await Service().get('emails').then(res => {
                    commit('SET_EMAILS', res.data);
                });
            } catch (e) {
                commit('SET_EMAILS', {});
                console.log('Error: ', e);
            }
        },

        async loadCountries({commit}) {
            try {
                await Service().get('countries').then(res => {
                    commit('SET_COUNTRIES', res.data);
                });
            }
            catch (e) {
                console.log(e)
            }

        },

        async updateEmailContents(_, payload) {
            try {
                return await Service().put('emails', payload);
            } catch (e) {
                console.log('Error: ', e);
            }
        },

        async fetchDropdowns({ commit }) {
            try {
                await Service().get('common/dropdowns').then(res => {
                    commit('SET_DROPDOWNS', res.data);
                });
            } catch (e) {
                console.log('Error: ', e);
            }
        },
    },

    mutations: {
        SET_LOADING: (state, status) => state.loading = status,
        SET_EMAILS: (state, payload) => state.emails = payload,
        SET_COUNTRIES: (state, payload) => state.countries = payload,
        SET_DROPDOWNS: (state, payload) => state.dropdowns = payload,
    }
}
