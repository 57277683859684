<template>
  <div class="main-content">
    <!-- Header -->
    <div class="header bg-gradient-orange pb-sm-9 pt-sm-5 pb-md-9 pt-md-5 text-center">
      <img src="/assets/img/logo.png" width="200" alt="">
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5 text-center">
              <div class="text-center text-muted mb-4">
                <small>Sign in with credentials</small>
              </div>
              <login-form />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/LoginForm/index";

export default {
  name: 'LoginLayout',
  components: {
    LoginForm
  },
}
</script>

