<template>
  <div>
    <side-nav-component />

    <div class="main-content" id="panel">
      <top-nav-component />
      <header-component/>
      <div class="container-fluid mt--6">
        <div class="row">
          <div class="col">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNavComponent from '@/components/shared/SideNavComponent';
import TopNavComponent from '@/components/shared/TopNavComponent';
import HeaderComponent from '@/components/shared/HeaderComponent';

export default {
  name: 'AdminLayout',
  components: {
    TopNavComponent,
    SideNavComponent,
    HeaderComponent,
  },
}
</script>

