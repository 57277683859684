/* eslint-disable no-unused-vars */
import Service from "@/services";
import { participantResponse } from "../../utils/participants.helper";
import Vue from "vue";

export default {
  namespaced: true,

  state: {
    events: [],
    event_participants: [],
    wordpress_form_data: [],
    event_fields: [],
    event_field: {},
    event_field_groups: [],
    event: {},
    participant: {},
    otpStatus: null,
  },

  getters: {
    events: (state) => state.events,
    wordpress_form_data: (state) => state.wordpress_form_data,
    event_fields: (state) => state.event_fields,
    event_field: (state) => state.event_field,
    event_field_groups: (state) => state.event_field_groups,
    event: (state) => state.event,
    participant: (state) => state.participant,
    otpStatus: (state) => state.otpStatus,
    eventParticipants: (state) => {
      if (state.event.participants) {
        return participantResponse(
          JSON.parse(JSON.stringify(state.event.participants))
        );
      }
      return [];
    },
    selectedParticipants: state => {
      if (state?.event?.participants) {
        return state.event.participants.filter(p => p?.selected === true)
      }

      return [];
    }
  },

  actions: {
    async event_wordpress_list({ commit }, payload) {
      try {

        return await Service()
            .get(`wordpress_data_list/${payload.id}?page=${payload.page}`)
            .then((res) => {
              console.log(res,"test")
              if(typeof res.data !== "undefined"){
                commit("SET_WORDPRESS_FORM_DATA", res.data);
              }else{
                commit("SET_WORDPRESS_FORM_DATA", []);
              }
              return res;
            });
      } catch (e) {
        console.log("Error: ", e);
      }
    },
    async events({ commit }, payload) {
      try {
        const page = payload.page;
        const form = payload.form;


        return await Service()
          .get(`events`, { params: { page, query: form } })
          .then((res) => {
            commit("SET_EVENTS", res.data);
            return res;
          });
      } catch (e) {
        console.log("Error: ", e);
      }
    },
    //will be deleted while making live
    async updateParticipantCategory({ commit }, payload) {
      try {
       // const page = payload.page;
        //const form = payload.form;

        return await Service()
            .post(`events/updateParticipantCategory`, payload)
            .then((res) => {
              console.log(res)
              //commit("SET_EVENTS", res.data);
              return res;
            });
      } catch (e) {
        console.log("Error: ", e);
      }
    },
    //will be deleted while making live
    async search({ commit }, payload) {
      try {
        const page = payload.page;
        const form = payload.form;

        return await Service()
          .post(`events/search?page=${page}`, form)
          .then((res) => {
            commit("SET_EVENTS", res.data);
            return res;
          });
      } catch (e) {
        console.log("Error: ", e);
      }
    },

    async game_events() {
      try {
        return await Service().get("events/game_events");
      } catch (e) {
        console.log("Error: ", e);
      }
    },

    async new({ commit }, payload) {
      try {
        return await Service()
          .post("events", payload)
          .then((res) => {
            commit("SET_EVENTS", res.data);
            return res;
          });
      } catch (e) {
        console.log("Error: ", e);
      }
    },

    async event_fields({ commit }, id) {
      try {
        return await Service()
          .get(`event_fields/${id}`)
          .then((res) => {
            commit("SET_EVENT_FIELDS", res.data);
            return res;
          });
      } catch (e) {
        console.log("Error: ", e);
      }
    },

    async event_field_groups({ commit }) {
      try {
        return await Service()
          .get("event_fields/groups")
          .then((res) => {
            commit("SET_EVENT_FIELD_GROUPS", res.data);
            return res;
          });
      } catch (e) {
        console.log("Error: ", e);
      }
    },

    async add_field({ commit }, payload) {
      try {
        return await Service()
          .post("event_fields/add", payload)
          .then((res) => {
            commit("SET_EVENT_FIELDS", res.data);
            return res;
          });
      } catch (e) {
        console.log("Error: ", e);
      }
    },

    async update_field({ commit }, { _id, ...payload }) {
      try {
        return await Service()
          .put(`event_fields/update/${_id}`, payload)
          .then((res) => {
            commit("SET_EVENT_FIELDS", res.data);
            return res;
          });
      } catch (e) {
        console.log("Error: ", e);
      }
    },

    async get_field({ commit }, payload) {
      try {
        return await Service()
          .get(`event_fields/field/${payload}`)
          .then((res) => {
            commit("SET_EVENT_FIELD", res.data);
            return res.data;
          });
      } catch (e) {
        console.log("Error: ", e);
      }
    },

    async event({ commit }, id) {
      try {
        return await Service()
          .get(`events/${id}`)
          .then((res) => {
            if (!res.data) return;
            commit("SET_EVENT", res.data);
            return res;
          });
      } catch (e) {
        console.log("Error: ", e);
      }
    },

    async getEvent({ commit }, { id, status,category }) {
      try {
        return await Service()
          .get(`events/${id}?status=${status}&category=${category}`)
          .then((res) => {
            if (!res.data) return;
            commit("SET_EVENT", res.data);
            return res;
          });
      } catch (e) {
        console.log("Error: ", e);
      }
    },

    async update({ commit }, payload) {
      try {
        return await Service()
          .post("events/update", payload)
          .then((res) => {
            commit("SET_EVENT", res.data);
            return res;
          });
      } catch (e) {
        if (e.errors) {
          commit("SET_ERRORS", e.errors, { root: true });
        }

        console.log("Error: ", e);
      }
    },

    async update_metadata(_, payload) {
      try {
        return await Service().post("events/update_metadata", payload);
      } catch (e) {
        console.log("Error: ", e);
      }
    },
    async remove_metadata(_, payload) {
      try {
        return await Service().post("events/remove_metadata", payload);
      } catch (e) {
        console.log("Error: ", e);
      }
    },

    async update_status({ commit }, payload) {
      try {
        return await Service()
          .post("participants/update_status", payload)
          .then((res) => {
            commit("SET_PARTICIPANTS", res.data);
            return res;
          });
      } catch (e) {
        console.log("Error: ", e);
      }
    },

    async downloadAttachment(_, payload) {
      try {
        return await Service().post("get_download_url", payload);
      } catch (e) {
        console.log("Error: ", e);
      }
    },

    async newTeam(_, payload) {
      try {
        return await Service().post("events/save_team", payload);
      } catch (e) {
        console.log("Error: ", e);
      }
    },

    async updateParticipantTeam(_, payload) {
      try {
        return await Service().post(
          "participants/update_participant_team",
          payload
        );
      } catch (e) {
        console.log("Error: ", e);
      }
    },

    async updateParticipantWeightHeight(_, payload) {
      try {
        return await Service().post('participants/update_participant_weight_height', payload);
      } catch (e) {
        console.log("Error: ", e);
      }
    },

    async addManualParticipant({ commit }, payload) {
      try {
        return await Service()
          .post("events/manual_participant", payload)
          .then((res) => {
            if (!res.data) return;

            let participant_response = participantResponse(res.data);

            if (participant_response[0])
              commit("ADD_EVENT_PARTICIPANT", participant_response[0]);
            return res.data;
          });
      } catch (e) {
        console.log("Error: ", e);
      }
    },

    async bulkUpdateParticipantsStatus(_, payload) {
      try {
        return Service().post("participants/bulk-action/update-status", payload)
      } catch (error) {
        console.error("bulk update participants status", error);
      }
    },

    async bulkUpdateParticipantsTeam(_, payload) {
      try {
        return Service().post("participants/bulk-action/update-team", payload)
      } catch (error) {
        console.error("bulk update participants team", error);
      }
    },

    async bulkImportParticipants(_, payload) {
      try {
        return Service().post("participants/import-csv", payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } catch (error) {
        console.error("bulk import participants", error);
      }
    }
  },

  mutations: {
    SET_EVENTS: (state, payload) => (state.events = payload),
    SET_WORDPRESS_FORM_DATA: (state, payload) => (state.wordpress_form_data = payload),
    SET_EVENT_PARTICIPANTS: (state, payload) => (state.event_participants = payload),
    ADD_EVENT_PARTICIPANT: (state, payload) => state.event_participants.push(payload),
    SET_EVENT_FIELDS: (state, payload) => (state.event_fields = payload),
    SET_EVENT_FIELD: (state, payload) => (state.event_field = payload),
    SET_EVENT_FIELD_GROUPS: (state, payload) => (state.event_field_groups = payload),
    SET_EVENT: (state, payload) => (state.event = payload),
    SET_PARTICIPANTS: (state, payload) => (state.participants = payload),
    SELECT_PARTICIPANT: (state, { selected, index }) => {
      if (state?.event?.participants && state.event.participants[index]) {
        Vue.set(state.event.participants[index], 'selected', selected);
      }
    },
    SELECT_PARTICIPANTS: (state, selected) => {
      if (state?.event?.participants) {
        state.event.participants.forEach(p => {
          Vue.set(p, 'selected', selected);
        })
      }
    }
  },
};
